.bookings-container{
  margin: 3rem 0; /*mar-top:4.5rem in design*/
}
.tabs-section{
  width: 100%;
  height: 3.5rem;
}

.active-tab, .completed-tab{
  width: 50%;
  text-align: center;
  border-bottom: 0.25rem solid #C4C4C4;
  padding-bottom: 0.25rem;
}

.active-tab p, .completed-tab p{
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.438rem;
  color: #595959;
  margin: 0;
}

.selected{
  /* border-bottom: 0.25rem solid #455A64; */
  border-bottom-color: #455A64;;
}

.selected p{
  color: #263238;
  font-weight: 500;
}

.booking-wrapper{
  padding: 1.5rem 1rem;
  gap: 1rem;
  overflow: auto;
  height: calc(100vh - 165px);
}