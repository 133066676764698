.drag-bar {
  padding: 0.75rem 0;
}

.drag-dark-bar {
  width: 2.625rem;
  height: 0.375rem;
  background-color: #c4c4c4;
  border-radius: 0.375rem;
}

.modal-content-box {
  border: none;
  outline: none;
}

.modal-content-header {
  margin-bottom: 1.5rem;
}

.modal-close-icon{
  position:absolute;
  top:1rem;
  right:1rem;
}
.modal-heading {
  font-size: 1.75rem;
  line-height: 2.563rem;
  font-weight: 700;
  color: #263238;
  margin: 1.438rem 0 0.25rem;
}

.modal-subheading {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #263238cc;
  margin: 0;
}

.small-subheading {
  width: 35ch;
}

.medium-subheading {
  width: 45ch;
}

.checkbox-container {
  margin: 2rem 0;
  width: 100%;
  gap: 0.25rem;
  padding: 0;
}

.package-checkbox-container {
  margin: 2rem 0;
  width: 100%;
  gap: 0.25rem;
  padding: 0 2.5rem 0 0;
  width: 18px;
  height: 18px;
}

.checkbox-item {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 21px;
  color: #263238;
  margin-bottom: 1.25rem;
}

.checkbox-item .custom-checkmark {
  top: 2px;
}

.modal-submit-btn {
  background-color: #2e2f2f;
  border-radius: 0.5rem;
  padding: 1rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  border: none;
  outline: none;
  width: 100%;
}

.btn-half {
  width: 50%;
}

.Overlay {
  background: rgba(38, 50, 56, 0.94);
  width: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.Overlay.Show {
  display: block;
}

.react-modal-sheet-container {
  position: fixed;
  border-radius: 24px 24px 0px 0px !important;
  bottom: -150vh;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  left: 0;
  transition: all 0.3s ease-out;
  z-index: 10;
  padding: 0.5rem 1rem 1rem;
  display: inline-block !important;
  height: max-content !important;
  max-height: 800px !important;
  margin: 2rem 0 0;
}

/* To avoid Bottom sheet exceeding the screen height */
@media screen and (max-height: 568px) {
  .react-modal-sheet-container {
    max-height: 550px !important;
  }
}

@media screen and (max-height: 700px) and (min-height: 569px) {
  .react-modal-sheet-container {
    max-height: 650px !important;
  }
}

.react-modal-sheet-container .react-modal-sheet-content {
  overflow: hidden !important;
}

/* .react-modal-sheet-content{
  height: max-content !important;
  overflow: hidden;
} */

.Modal {
  position: fixed;
  border-radius: 24px 24px 0px 0px;
  bottom: -150vh;
  background-color: #fff;
  width: 100%;
  height: fit-content;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  left: 0;
  transition: all 0.3s ease-out;
  z-index: 10;
  padding: 0.5rem 1rem 2rem;
}

.Modal.Show {
  bottom: 0;
}

.Close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 10px;
  background-color: transparent;
  border: 0;
  font-size: 18px;
}

.bottom-btns {
  gap: 0.5rem;
  height: 3.563rem;
  margin-top: 1.5rem;
}

.price-box {
  gap: 0.25rem;
  padding: 0.375rem 0rem;
}

.modal-content-box {
  overflow: hidden;
}

.price-box p {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #2e2f2f;
  margin: 0;
}

.amount {
  gap: 0.125rem;
  height: 30px;
}

.amount h6 {
  font-size: 1.25rem;
  line-height: 1.438rem;
  color: #2e2f2f;
}

.list-heading {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #263238;
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.package-details-list {
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
}

.package-details-list ::marker {
  color: rgba(38, 50, 56, 0.8);
  font-size: 0.8rem;
}

.package-details-list p {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: rgba(38, 50, 56, 0.8);
  margin: 0;
}

.total-amount-container {
  margin: 1.5rem 0 0;
}

.totalText {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: rgba(38, 50, 56, 0.8);
  margin: 0;
}

.info-amount {
  gap: 0.125rem;
}

.info-amount h6 {
  font-size: 1.14rem;
  line-height: 1.688rem;
  font-weight: 700;
}

.medium-icon img {
  width: 18px;
  height: 18px;
}

.price-icon-m img {
  margin: 0 0.125rem 0 0;
}

.height-0 {
  height: fit-content;
}

.padding-b-16 {
  padding-bottom: 1rem;
}

.pay-btns {
  margin-top: 1.5rem;
  gap: 0.5rem;
}

.amount-item p {
  margin: 0;
}

.dateChange-container {
  margin: 0 0 1.5rem;
  max-height: 270px;
  overflow-y: auto;
}

.date-container {
  padding: 10px 0;
}

.date-container label {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #263238;
  font-weight: 500;
}

.modal-input-field {
  position: relative;
  margin: 0 0 1.5rem;
}

.modal-input-field input {
  width: 100%;
  height: 56px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem 0.75rem;
  outline: none;
  color: #000000;
  font-size: 1rem;
}

/* .modal-input-field::before{
  position: absolute;
  content: 'Add Custom';
  color: #ED7D2B;
  right: 12px;
  top: 16px;
} */

.modal-add-custom {
  color: #ed7d2b;
  position: absolute;
  right: 12px;
  top: 16px;
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  border: none;
  outline: none;
  background-color: transparent;
}

.car-select-container {
  gap: 0.25rem;
  margin: 0 0 0.5rem;
  max-height: calc(100vh - 280px);
  overflow: auto;
  width: 100%;
  display: inline-block;
}

.car-container {
  width: 100%;
  padding: 0.5rem;
}

.car-container label {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #263238;
}

.car-container label input {
  display: none;
}

.vehicle-container-issue {
  grid-gap: 0.5rem;
  gap: 0.5rem;
  white-space: nowrap;
  margin-bottom: 1.5rem;
  padding: 0 0 1rem;
  overflow-x: auto;
  overflow-y: hidden;
}

.vehicle-container-issue .icon-container {
  display: none;
}

.vehicle-container-issue .custom-checkmark {
  display: none;
}

.vehicle-container-issue .car-details {
  margin: 0;
}

.checkbox-container h4 {
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #262626;
  margin: 0;
}

.secondary-submit-btn,
.primary-submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-submit-btn .btn-icon,
.primary-submit-btn .btn-icon{
  margin-right: 9px;
}
/* whatsapp */
.primary-submit-btn#chat-btn {
background-color: #41C536;
}

@media screen and (min-width: 350px) {
  .price-box {
    padding: 0.375rem 1rem;
  }
}

@media screen and (min-width: 400px) {
  .price-box {
    padding: 0.375rem 1rem;
  }
}

@media screen and (min-height: 630px) {
  .dateChange-container {
    max-height: 340px;
  }
}

@media screen and (min-height: 700px) {
  .dateChange-container {
    max-height: 430px;
  }
}

@media screen and (min-height: 800px) {
  .dateChange-container {
    max-height: 500px;
  }
}