.service-card#notify-card {
    background-color: white;
    padding: 0;
}

.service-card#notify-card .service-card-right,
.service-card#notify-card .service-card-right a {
   width: 100%;
   text-align: center;
}

.service-card#notify-card .service-card-btn {
    background-color: white;
    padding: 0;
    width: 100%;
    padding: 17px 10px;
    background-color: #9DFB64;
    text-align: center !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-right: 8px;
    color: black;
    margin: auto;
    display: block;
}
.service-card#notify-card .service-card-btn span{
    text-align: center;
}

.service-card#notify-card .service-card-btn .whatsapp-icon{
    margin-left: 8px;
}
