.second-home-wrapper, .temp-color{
  background-color: #FFEAC3;
  /* padding: 4rem 0 0; */
}

.trans-header{
  height: 56px;
  width: 100%;
  position: relative;
}

/* .owner-banner-container{
  padding: 5.5rem 1rem 0;
} */

.owner-details-banner{
  background: #353535;
  text-align: center;
  border-radius: 1rem;
  max-height: 180px;
  width: 100%;
  padding: 1.063rem 1rem;
  margin: 0 0 2rem;
}

.owner-details{
  gap: 0.625rem;
}

.owner-name p, .vehicle-model p, .vehicleNo p{
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #FFFFFF;
  margin: 0;
}

.owner-name h6, .vehicle-model h6, .vehicleNo h6{
  font-size: 1rem;
  line-height: 1.438rem;
  font-weight: 700;
  color: #FFFFFF;
  margin: 0;
}

.issue-form{
  background-color: #FFFFFF;
  width: 100%;
  padding: 1rem 1rem 5rem;
  border-radius: 1rem 1rem 0px 0px;
}

.issue-form-options{
  margin-bottom: 4.375rem;
}

.issue-form-header{
  gap: 0.25rem;
  margin-bottom: 2rem;
}

.issue-form-header h4{
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.188rem;
  color: #263238;
  margin: 0;
}     

.issue-form-header p{
  margin: 0;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.issue-item-container{
  padding: 0.5rem 0;
  height: max-content;
  margin: 0 0 0.75rem;
  align-items: center;
}

.issue-item-container label{
  font-weight: 500;
  color: #263238;
  font-size: 0.875rem;
  line-height: 1.313rem;
}

.issue-btn{
  gap: 0.5rem;
  width: 130px;
}

.vehicle-number-container {
  width: 80%;
  height: 56px;
  margin-right: 8px;
  position: relative;
}
.vehicle-number-container label {
  position: absolute;
  top: -11px;
  left: 10px;
  background-color: #FFFFFF;
  padding: 0 5px;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 500;
}
.vehicle-number-container input {
  width: 100%;
  height: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  padding: 0.75rem 1rem;
}
.vehicle-number-container input,
.mobile-number-container input {
  width: 100%;
  height: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  padding: 0.75rem 1rem;
}
.mobile-number-container {
  padding-bottom: 1.5rem;
}
.issues-bottom{
  width: 100%;
  max-height: 150px;
  position: fixed;
  bottom: 0;
  background: #fff;
  padding: 0.7rem 1rem 2rem;
}
