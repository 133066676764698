.card-border{
  border: 0.063rem solid rgba(224, 224, 224, 1);
  border-radius: 1rem;
  margin-bottom: 1.5rem;
}

.card-border:nth-of-type(2){
margin-bottom: 0.75rem;
}

.small-border{
  border: 0.063rem solid #E0E0E0;
  border-radius: 0.5rem;
}

.car-padding-wrapper{
  padding: 0.75rem;
}

.car-card-center{
  padding: 0 1.8rem 0 0;
  gap: 0.125rem;
  margin: 0;
}

.center-extra-gap{
  gap:0.25rem
}

.car-card-heading{
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: #263238;
  font-weight: 500;
  margin: 0;
}

.car-card-subheading{
  font-size: 0.625rem;
  line-height: 1.188rem;
  color: #9B9D9D;
  margin: 0;
}

.car-card-details{
  font-size: 0.75rem;
  line-height: 1.188rem;
  color: #DD7F30;
  min-width: 3.563rem;
  margin: 0;
  min-width: 66px;
}

.car-card-price{
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #2E2F2F;
  font-weight: 700;
  margin: 0;
}

.price-icon img {
  margin: -0.625rem 0 0 0;
}

.small-icon img{
  width: 0.875rem;
  height: 0.875rem;
  margin: -0.425rem 0.25rem 0 0;
}

.price-amount{
  font-size: 1.25rem;
  line-height: 1.438rem;
  color: #2E2F2F;
  margin-right: 0.125rem;
  font-weight: 500;
}

.card-check input[type = checkbox]:checked + .card-border{
  border: 1px solid rgba(46, 47, 47, 1);
  background: rgba(255, 248, 234, 1);
}

.car-card-container{
  padding: 0.5rem 0rem;
  transition: 0.2s ease;
}

.mar-left-8{
  margin-left: 0.5rem;
}

.right-arrow{
  margin-bottom: 0.625rem;
}

.car-details-level, .car-onboard-level, .car-internal-level{
  padding: 0.75rem 0.938rem;
}

.booking-card-container{
  background: #FFFFFF;
  box-shadow: 0px 0px 0.625rem rgba(129, 129, 129, 0.25);
  border-radius: 1rem;
  padding: 0;
}

.car-internal-level{
  border-radius: 0 0 1rem 1rem;
}

.booking-margin-0{
  margin: 0;
}

.primary-pay-btn{
  border-radius: 0.5rem;
  padding: 0.313rem 1rem;
  outline: none;
  width: 5.625rem;
}

.active-package {
  background: #FFF8EA;
  border: 1px solid #2E2F2F;
}

.active{
  background-color: #000000;
  border: 0.063rem solid #000000;
  padding: 0.313rem 0.7rem;
}

.active p{
  font-size: 0.75rem;
  line-height: 1.375rem;
  color: #FFFFFF;
  margin: 0;
}

.complete{
  border: 0.063rem solid #D9D9D9;
  background: #F5F5F5;
}

.complete p{
  font-size: 0.75rem;
  line-height: 1.375rem;
  color: #BFBFBF;
  margin: 0;
}

.car-onboard-level{
  border-radius: 0px;
}

.active-onBoard{
  background: #EEECFF;
}

.complete-onBoard{
  background: #FAFAFA;
}

.complete-internal{
  background: #F5F5F5;
}

.active-internal{
  background: #E0DDF8;
}

.date-2-container{
  text-align: end;
}

.date-heading{
  font-weight: 500;
  color: #263238;
  font-size: 0.75rem;
  line-height: 1.188rem;
  margin: 0;
}

.date-value{
  font-size: 0.75rem;
  line-height: 1.188rem;
  margin: 0;
  color: #9B9D9D;
}

.skeleton{
  background: #F6F6F6;
  border-radius: 16px;
}

.skeleton.car{
  width: 379px;
  height: 193px;
}

.skeleton.bike{
  width: 379px;
  height: 133.25px;
}

.booking-card-center{
  padding: 0 1.2rem 0 0;
  margin: 0;
}

.mar-0{
  margin: 0;
}

.pad-right-big{
  padding: 0 4rem 0 0;
}

.car-card-right {
  margin-left: auto;
}