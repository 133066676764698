.sanitisation-container{
  padding: 1rem;
  margin-top: 3.5rem;
}

.sanitisation-header{
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.438rem;
  color: #263238;
  margin-bottom: 12px;
}

.sanitisation-subheading{
  font-size: 0.75rem;
  line-height: 1.188rem;
  color: #26323880;
  margin: 0 0 1.25rem 0;
}