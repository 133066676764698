.homescreen-footer{
  width: 100%;
  height: 3.813rem;
  position: fixed;
  bottom: 0;
  /* background-color: #000; */
  background: #F8F8F8;
  padding: 0.5rem 2rem;
}

.content{
  width: 70px;
  opacity: 0.6
}

.active-icon{
  opacity: 1;
  width: 70px;
}

.active-text{
  font-weight: 700;
  color: #2E2F2F; 
}

.footer-text{
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: #263238;
  font-weight: 500;
}