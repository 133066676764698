.profile-wrapper {
  padding: 4.5rem 1rem 2.5rem;
}

/* user details */
.user-detail {
  padding: 12px;
  background-color: #EBFBE4;
  border-radius: 8px;
  margin-bottom: 50px;
}

.user-detail h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 8px;
}

.user-detail p {
  margin-bottom: 0;
  line-height: 24px;
  margin-left: 12px;
}

.user-detail .user-contact {
  margin-bottom: 2px;

}

.user-detail .user-contact,
.user-detail .user-email {
  color: rgba(38, 50, 56, 0.5);
padding: 2px 0;
}


.menu-container {
  padding: 1rem 0;
  gap: 0.25rem;
}

.menu-item {
  padding: 1rem 0;
}

.item-content p {
  margin: 0 0 0 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #263238;
  font-weight: 500;
  font-weight: 600;
}