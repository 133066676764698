.issue-card-container{
  padding: 0.5rem;
  border: 0.063rem solid #F5F5F5;
  border-radius: 0.5rem;
}

.issue-completed{
  opacity: 0.5;
}

.issue-name{
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #263238;
  font-weight: 500;
  margin: 0;
}

.issue-date{
  margin: 0;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #595959;
}

.issue-list{
  padding: 0 1rem;
  margin: 0;
}

.issue-list li{
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #9B9D9D;
}
