.car-box-container {
  width: 138px;
  height: 100%;
  margin: 0;
  padding: 0.5rem;
  background-color: #fff;
  border: 0.063rem solid rgba(224, 224, 224, 1);
  border-radius: 8px;
  justify-content: center;
  transition: 0.2s ease;
}

.car-details{
  margin: 0.5rem 0 0 0;
  text-align: center;
}

.icon-container{
  position: relative;
}

.icon-container input{
  position: absolute;
  top: 0;
  right: 0;
}

.car-name{
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: #263238;
  font-weight: 500;
  margin: 0 0 0 0;
  text-align: center;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.car-number{
  font-size: 0.625rem;
  line-height: 1.188rem;
  color: #9B9D9D;
  margin: 0;
}

.car-icon {
  margin: 0 0.625rem 0 0;
}

.car-box-active {
  background: #FFF8EA;
  border: 1px solid #2E2F2F;
}