.user-info-wrapper{
  padding: 4rem 1rem 2rem;
}
.user-infoForm {
  height: calc(100vh - 100px);
}
.user-info-btn {
  margin-top: auto;
}

.user-form{
  height: auto;
}
.form-wrapper{
  padding-bottom: 6rem;
}
.btn-fixed {
  position: fixed;
  bottom: 0;
  width: calc(100%);
  padding: 1rem;
  background-color: #fff;
}

.btn-fixed button{
  position: sticky;
  bottom: 1rem;
  width: calc(100%);
  margin: 0;
}
