.select_vehicle_type_container {
    padding-top: 60px;
    min-height: 100vh;
}

.select_vehicle__header {
    font-style: normal;
    font-weight: 700;
    font-size: 29px;
    line-height: 47px;
    padding: 0 1rem;
    color: #263238;
    margin-bottom: 2rem;
}

.select_vehicle_type_container .img_container {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.select_vehicle_type_container .img_container img {
    width: 100%;
    /* max-width: 400px; */
    max-height: 320px;
}

.select_vehicle_type_container__buttons{
    margin-top: auto;
    padding: 0 16px;
    margin-bottom: 2.875rem;
}
.select_vehicle_type_container__button {
    background: #FFEEEE;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    border: 0;
    margin-bottom: 1rem;
}
.select_vehicle_type_container__button .button_right{
    margin-left: auto;
}
.select_vehicle_type_container__button .button_right p {
    margin-bottom: 0;
    margin-right: 15px;
    font-weight: 500;
}
