.vehicle-container{
  margin: 4.5rem 0;
  padding: 0 1rem;
}

.vehicle-wrapper{
  margin : 0.75rem 0 0;
  gap: 0.5rem;
}

.bikes-container{
  margin-top: 1.5rem;
}