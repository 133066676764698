.subscription-wrapper{
  padding: 1rem 1rem 6.5rem;
  height: fit-content;
  margin: 3.5rem 0  0;
}

.section-heading{
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #263238;
  margin: 0;
}

.extra-yellow-btn{
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #DD7F30;
  margin: 0;
}

.carpackage-subheading{
  font-size: 0.75rem;
  line-height: 1.188rem;
  color: #26323880;
  margin: 0;
}

.cards-container{
  max-width: 100%;
  min-height: 105px;
  overflow-y: hidden;
  overflow-x: auto;
  cursor: grabbing;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 1000px;
  padding-bottom: 0.625rem;
}

.card-wrapper{
  gap: 0.5rem;
  white-space: nowrap;
  /* padding: 0 1000px; */
}

.carpackage-header-container, .onboarding-header, .optional-header{
  margin: 0 0 1rem 0;
}

.onboarding-btns, .optional-btns{
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.onboard-btn{
  line-height: 1.375rem;
  font-size: 0.875rem;
  padding: 0.563rem 0;
  height: 40px;
}

.selected-package-container{
  margin: 1.875rem 0;
}

.selected-header{
  margin: 0 0 1.25rem 0;
}

.price-box-header{
  gap: 0.5rem;
}

.subscription-footer{
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 1rem 0.5rem;
  gap: 0.2rem;
  background: #FAFAFA;
}

.subscription-btn{
  padding: 1rem 2rem;
  margin: 0;
}

.subscription-btn p{
  margin: 0;
}

.optional-btn{
  padding: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  position: relative;
}

.optional-btn .arrow-down-icon{
  position: absolute;
  right: 11px;
}

@media screen and (min-width: 400px) {
  .subscription-footer{
    padding: 1rem 1rem;
    gap: 0.5rem;
  }
  .subscription-btn{
    padding: 1rem 2.375rem;
  }
}