.login-image{
  padding: 2.063rem 1rem 1.5rem;
  min-height: 200px;
  max-height: 50vh;
}

.login-content{
  padding: 0 1rem 5rem;
}

.login-form{
  width: 100%;
  gap: 1rem;
}

.login-heading{
  font-family: Heebo;
  font-size: 2rem;
  line-height: 2.938rem;
  margin-bottom: 0.2rem;
  margin-top:4.5rem;
  font-weight: 700;
}
.login-subheading {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #263238;
  opacity: 0.5;
  margin-bottom: 2.2rem;
}
.input-fields{
  width: 100%;
  margin-bottom: 0rem;
  position: relative;
}

.verified-icon{
  position: absolute;
  right: 12px;
  bottom: 15px;
}

.termsCheck {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 21px;
  color: #263238;
  margin-bottom: 1rem;
}
.termsCheck>span {
  margin-top: 3px;
}

.input-fields label{
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin-bottom: 0.25rem;
}

.input-fields input{
  width: 100%;
  height: 56px; 
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem 0.75rem;
  color: #262626;
  outline: none;
  background-color: transparent !important;
}

.input-fields > button{
  width: 100%;
  height: 56px; 
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem 0.75rem;
  color: #262626;
  outline: none;
  background: transparent;
}

.input-fields input::placeholder{
  color: rgba(38, 38, 38, 0.5);
}

.input-fields input:hover{
  border: 1px solid #40A9FF;
}

.input-fields input:focus, input:focus-visible, input:focus-within{
  box-shadow: 0px 0px 4px 0px #1890FF80;
  border: 1px solid #1890FF
}

.login-checkbox-container {
  width: 100%;
}

.login-checkbox-container label{
  padding-left: 0;
}

.checkbox-field{
  margin: 1rem 0 2rem;
}

.checkbox-field input{
  margin-right: 0.5rem;
  margin-top: 0.25rem;
}

.checkbox-field label, .custom-links{
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #263238;
  outline: none;
}

.custom-links{
  font-weight: 700;
}

.custom-links:hover{
  color: #263238;
}

.checkbox-field label span{
  font-weight: 700;
}

.primary-submit-btn p{
  margin: 0;
  color: #FFFFFF;
}
