.vehicle-header{
  gap: 0.25rem;
  margin: 0 0 1rem;
  width: 100%;

}

.fuel-type{
  margin: 0;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: #262626;
}

.fuel-btns{
  gap: 0.25rem;
}

.vehicle-submit-btn{
  margin: 5rem 0 0;
}

.vehicle-field{
  position: relative;
}

.vehicle-field::before{
  position: absolute;
  content: url('../../assets/icons/down-arrow.svg');
  top: 19px;
  right: 19px; 
}

.vehicle-field p {
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #8C8C8C;
}

.car-selected{
  color: #2E2F2F !important;
}

.input-fields .dropdown {
  width: 100%;
  height: 56px; 
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem 0.75rem;
  color: #262626;
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;
}

.dropdown .btn-primary{
  background: transparent;
  outline: none;
  border: none;
  width: 100%;
  padding: 0;
  color: #8C8C8C;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
}

.btn-primary:hover, .btn-primary:active:focus, .btn:focus, .show>.btn-primary.dropdown-toggle:focus{
  color: #8C8C8C;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.dropdown-toggle::after{
  position: absolute;
  content: url('../../assets/icons/down-arrow.svg') !important;
  top: 19px;
  right: 19px; 
  border: none !important;
}

.dropdown-menu{
  width: 100%;
  border-radius: 0.5rem !important;
  border: 0.063rem solid #D9D9D9 !important;
  box-shadow: 0.25rem 0.25rem 0.625rem rgba(162, 162, 162, 0.25) !important;
}

.dropdown-menu .dropdown-item{
  padding: 1rem 1rem !important;
}

.dropdown-menu.show{
  transform: translate3d(0, 45px, 0);
  margin-left: -0.75rem;
  margin-top: 1rem;
  display: flex !important;
  flex-direction: column;
  /* gap: 1rem; */
}

.parking-selected{
  color: #000000 !important;
}
.user-info-wrapper .form-heading{
  font-size: 1.5rem;
  line-height: 2.188rem;
  color: #263238;
  font-weight: 700;
  width: 100%;
  text-align: left;
  margin-bottom: 32px;

}

.form-wrapper {
  padding-bottom: 6rem;
}
.btn-fixed {
  position: fixed;
  bottom: 0;
  width: calc(100%);
  padding: 1rem;
  background-color: #fff;
}

.btn-fixed button{
  bottom: 1rem;
  width: calc(100%);
  margin: 0;
}
