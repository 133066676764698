.homescreen-container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.homescreen-wrapper{
  padding: 4rem 1rem 5rem;
}

.banner{
  border-radius: 1rem;
  background-color: #FFEAC3;
  margin-bottom: 2.063rem;
}

.banner-content{
  margin: 1rem 0 1.125rem 1rem;
}

.banner-heading{
  font-size: 1rem;
  line-height: 1.438rem;
  font-weight: 700;
  color: #263238;
  margin: 0;
}

.banner-subheading{
  font-size: 0.625rem;
  line-height: 0.938rem;
  color: #263238;
  font-weight: 500;
}

.banner-btn{
  border: none;
  outline: none;
  background-color: #263238;
  border-radius: 0.25rem;
  padding: 0.125rem 0.5rem;
  width: 4rem;
}

.banner-btn p{
 color: #FFFFFF;
  font-size: 0.625rem;
  line-height: 0.938rem;
  font-weight: 500;
  margin: 0;
}

.banner-image img{
  height: 100%;
  width: 100%;
  border-radius: 1rem;
}

.homescreen-services{
  padding: 0;
}

.homescreen-service-card{
  box-shadow: none !important;
}

.homescreen-service-card:nth-of-type(n+2){
  margin: 0.5rem 0 0 0;
}