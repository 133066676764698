.website-container {
  width: 100%;
  padding: 1.5rem 1rem;
  background-color: #ffffff;
}

.intro-image-container {
  max-height: 356px;
  margin: 0.5rem 0rem 3.25rem;
  /* background: #EDEDED; */
  border-radius: 16px;
  padding-top: 2rem;
}

/* @media only screen and (max-height: 736px) {
  .intro-image-container {
    max-height: 200px;
  }
} */

.services-user {
  /* background: #F7F7F7; */
  border-radius: 16px 16px 0px 0px;
  padding: 1rem;
  max-height: calc(100vh - 250px);
  overflow: auto;
}

.service-heading {
  font-weight: bold;
  font-size: 1rem;
  line-height: 23px;
  color: #263238;
  margin: 0;
}

.service-subheading {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #838383;
  margin: 0 0 1rem;
}

.service-card {
  background: #FFFFFF;
  border: 0.5px solid #E0E0E0;
  border-radius: 16px;
  padding: 1rem;
  margin: 1rem 0 0;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 12px 3px #D7D7D740;
}

.login-btn {
  display:flex;
  justify-content:center;
  align-items:center;
  background: #263238;
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom:1.5rem;
  color: #FFC32A;
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  width:100%;
  text-align:center;
  color:white;
}
.login-btn .btn-icon{
  margin-left: 0.5rem;
}
/* introscreen service card */
.introscreen-service-card {
  background-color: #FDF0CC;
  border-radius: 8px;
  border: 0;
}

.service-card .service-card-left .card-icon {
  width: 42px;
  height: 42px;
  background: #F5F5F5;
  border-radius: 10px;
  margin: 0 0 1rem;
}

.service-card .service-card-left .card-heading {
  color: #263238;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 19px;
  margin: 0;
}

.service-card .service-card-left .card-detail {
  font-weight: normal;
  font-size: 0.625rem;
  line-height: 15px;
  color: #263238;
  margin: 0;
}

.service-card .service-card-right {
  display: flex;
  align-items: center;
}

.service-card .service-card-btn {
  background: #263238;
  border-radius: 22px;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #FFC32A;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
}

.service-card .service-card-btn .cardBtn-icon {
  margin-left: 0.5rem;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
}

.service-card.introscreen-service-card .service-card-right button {
  border-radius: 8px;
}

.homescreen-btn {
  width: 120px;
  justify-content: center;
  align-items: center;
}

.primary-submit-btn {
  width: 100%;
  padding: 1rem;
  background: #263238;
  /* opacity: 0.2; */
  border-radius: 8px;
  border: none;
  outline: none;
  color: #FFFFFF;
}

.primary-submit-btn:disabled {
  opacity: 0.2;
}

::-webkit-scrollbar-thumb {
  width: 0;
  display: none;
}

.secondary-submit-btn {
  width: 100%;
  padding: 1rem;
  background: #FFFFFF;
  /* opacity: 0.2; */
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  outline: none;
}

.secondary-submit-btn p {
  color: #262626;
  margin: 0;
}

.secondary-submit-btn:disabled {
  opacity: 0.2;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

/* ---- Check Box ---- */
.checkbox-label {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 1.5rem;
}

.checkbox-label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.custom-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 16px;
  height: 16px;
  border: 1px solid #000000;
  border-radius: 2px;
  background-color: #ffff;
}

.custom-checkmark:after {
  content: "";
  position: absolute;
  /* display: none; */
  opacity: 0;
  top: 1px;
  left: 4.4px;
  height: 9px;
  width: 4.5px;
  border: solid #ffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transition: 0.3s ease;
}

.checkbox-label input:checked~.custom-checkmark {
  background-color: #2E2F2F;
}

.checkbox-label input:checked~.custom-checkmark::after {
  /* display: block; */
  opacity: 1;
}

/* ---- Radio Btn ---- */
.radiobtn-label {
  display: flex;
  align-items: center;
  height: 18px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 1.875rem;
}

.radiobtn-label input[type="radio"] {
  position: absolute;
  cursor: pointer;
  opacity: 0;
}

.radiobtn-label .radio-custom {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  ;
  border-radius: 50%;
  box-sizing: border-box;
}

.radiobtn-label .radio-custom:after {
  content: "";
  position: absolute;
  display: none;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #263238;
}

.radiobtn-label input[type="radio"]:checked+.radio-custom {
  border-color: #263238;
}

.radiobtn-label input[type="radio"]:checked+.radio-custom:after {
  display: block;
}


/* introscreen service buttons */
.homescreen-container .buttons {
  margin-top: 24px;
}

.buttons .introscreen-service-button {
  width: 100%;
  border: 0;
  padding: 17px 10px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.buttons .introscreen-service-button h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-right: 8px;
}

.buttons .introscreen-service-button.btn-1 {
  background-color: #9DFB64;
}

.buttons .introscreen-service-button.btn-2 {
  background-color: #FB646D;
}

/* qrscreen */