.profile-banner{
  background: #EBFBE4;
  border-radius: 0.5rem;
  padding: 0.75rem;
  gap: 0.25rem;
  margin: 0 0 1rem;
}

.profile-contact-details{
  gap: 0.125rem;
}

.profile-owner{
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: #263238;
  margin: 0;
}

.profile-phone p, .profile-email p {
  margin: 0 0 0 0.5rem;
  line-height: 1.5rem;
  font-size: 0.75rem;
  color: #26323880;
}