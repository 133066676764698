.login-otp-container{
  padding: 1rem 1rem;
  margin: 4.5rem 0;
}

.heading-otp .heading{
  font-size: 2rem;
  line-height: 2.188rem;
  color: #263238;
  margin-bottom: 0.125rem;
  font-weight: 700;
}

.heading-otp .subheading{
  color: #263238;
  opacity: 0.5;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.sub-text .otp-incorrect{
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #FD2222;
  display: none;
}

.otp-boxes{
  margin-bottom: 1rem;
  flex-grow: 1;
  gap: 0.5rem;
}

.otp-box{
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  height: 56px;
  width: 100%;
  padding: 1rem 0.75rem;
  outline: none;
  white-space: 4px;
}

.otp-box:first-of-type{
  margin-left: 0;
}

.otp-box:last-of-type{
  margin-right: 0;
}

.otp-box:hover{
  border: 1px solid #40A9FF;
}
.otp-box:focus, .otp-box:focus-visible, .otp-box:focus-within{
  box-shadow: 0px 0px 4px 0px #1890FF80;
  border: 1px solid #1890FF
}

.resend-prompt{
  font-size: 1rem;
  line-height: 1.5rem;
  /* color: #263238; */
  margin: 0;
  outline: none;
  border: none;
  background-color: transparent;
}

.timer-prompt p{
  margin: 0 0 0 0.5rem;
}

.timer-image{
  padding: 0.042rem 0.125rem 0.083rem;
}

.prompts{
  margin: 0 0 2.5rem;
}

.heading-otp{
  margin: 0 0 2rem;
}